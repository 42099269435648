// Generated by Framer (3ae0a1a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qBfgEy70j", "nb8L5XvbY"];

const serializationHash = "framer-0ZQMY"

const variantClassNames = {nb8L5XvbY: "framer-v-kcxtbp", qBfgEy70j: "framer-v-1mdyh1d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "qBfgEy70j", Mobile: "nb8L5XvbY"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qBfgEy70j"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qBfgEy70j", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1mdyh1d", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"qBfgEy70j"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({nb8L5XvbY: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0ZQMY.framer-8247xi, .framer-0ZQMY .framer-8247xi { display: block; }", ".framer-0ZQMY.framer-1mdyh1d { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 429px; overflow: visible; padding: 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0ZQMY.framer-1mdyh1d { gap: 0px; } .framer-0ZQMY.framer-1mdyh1d > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-0ZQMY.framer-1mdyh1d > :first-child { margin-top: 0px; } .framer-0ZQMY.framer-1mdyh1d > :last-child { margin-bottom: 0px; } }", ".framer-0ZQMY.framer-v-kcxtbp.framer-1mdyh1d { min-height: 919px; width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 429
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"nb8L5XvbY":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGhtSfbF9Z: React.ComponentType<Props> = withCSS(Component, css, "framer-0ZQMY") as typeof Component;
export default FramerGhtSfbF9Z;

FramerGhtSfbF9Z.displayName = "Footer";

FramerGhtSfbF9Z.defaultProps = {height: 429, width: 1200};

addPropertyControls(FramerGhtSfbF9Z, {variant: {options: ["qBfgEy70j", "nb8L5XvbY"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerGhtSfbF9Z, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})